<template>
  <!-- Enroll Modal -->
  <b-modal id="enroll-modal" centered hide-footer>
    <div>
      <div
        class="
          d-flex
          font-weight-bolder
          justify-content-center
          align-items-center
          mb-1
        "
      >
        <b-avatar variant="light-success" class="mr-1 subject-icon" icon="21">
          <i :class="classroomData.icon" class="font-medium-2 ti ti-book" />
        </b-avatar>
        {{ classroomData.title }}
      </div>

      <b-form @submit.prevent="joinTraining">
        <b-row>
          <b-col lg="12" class="mb-1" v-if="classroomData.code">
            <b-form-group
              v-slot="{ ariaDescribedby }"
              label="Kode"
              :state="errors.code ? false : null"
              :invalid-feedback="errors.code ? errors.code[0] : ''"
            >
              <b-form-input
                :aria-describedby="ariaDescribedby"
                v-model="form.code"
                placeholder="Masukkan Kode"
                :state="errors.code ? false : null"
              />
            </b-form-group>
          </b-col>
          <b-col lg="12" class="mb-1" v-if="classroomData.requirement_description">
            <b-form-group
              v-slot="{ ariaDescribedby }"
              :state="errors.requirement_file ? false : null"
              :invalid-feedback="errors.requirement_file ? errors.requirement_file[0] : ''"
            >
            <label class="d-block" v-html="classroomData.requirement_description"></label>
            <b-form-file
              :aria-describedby="ariaDescribedby"
              v-model="form.requirement_file"
              :state="errors['requirement_file'] ? false : null"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              class="mb-1"
            ></b-form-file>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label="Persyaratan Pelatihan" v-if="classroomData.requirements.length">
          <ol>
            <li v-for="training in classroomData.requirements" :key="training.id">{{ training.title }}</li>
          </ol>
        </b-form-group>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="w-100"
          variant="primary"
          type="submit"
        >
          <i class="font-medium-2 ti ti-circle-plus" />
          Daftar Pelatihan
        </b-button>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
import { BAvatar, BModal, BButton, BForm, BFormInput, BFormFile, BFormGroup, BRow, BCol } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { reqJoinTraining } from '@/api/user/training'

export default {
  components: {
    BAvatar,
    BModal,
    BButton,
    BForm,
    BFormInput,
    BFormFile,
    BFormGroup,
    BRow,
    BCol
  },
  directives: {
    Ripple,
  },
  props: {
    classroomData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        code: "",
        requirement_file: null
      },
      errors : []
    };
  },
  methods: {
    joinTraining() {
      const data = new FormData
      if (this.form.requirement_file) {
        data.append('requirement_file', this.form.requirement_file)
      }
      if (this.form.code) {
        data.append('code', this.form.code)
      }

      reqJoinTraining(this.classroomData.id, data).then(response => {
        this.$emit('reload')
        this.$swal({
          title: 'Berhasil',
          text: response.data.message,
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Oke',
        }).then(()=>{
          this.$bvModal.hide('enroll-modal') 
          this.form.requirement_file = null
        })
      }).catch(error=>{
        this.$swal({
          title: 'Gagal',
          text: error.response.data.message || 'Gagal mendaftar pelatihan',
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Oke',
        })
        this.errors = error.response.data.errors
      })
    },
  },
};
</script>

<style></style>
