<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <!-- Header -->
          <div class="d-flex align-items-center line-height-1 pb-2">
            <i class="text-primary ti ti-users small-gap-right" />
            <h4 class="text-primary mb-0">Review Pelatihan</h4>
          </div>
          <b-row v-if="isLoading">
            <b-col class="d-flex justify-content-center mt-4">
              <div class="text-center mt-4">
                <b-spinner
                  variant="primary"
                  class="mr-1"
                />
                <p class="mt-1">
                  Memuat Data
                </p>
              </div>
            </b-col>
          </b-row>
          <div class="" v-else-if="!isDone">
            <div class="card p-2 mb-1 bg-danger text-white">
              <div class="d-flex">Anda harus menyelesaikan training ini terlebih dahulu</div>
            </div>
          </div>
          <div v-else>
            <b-row>
              <b-col lg="12" class="mb-1">
                <label class="d-block">Rating</label>
                <b-form-radio-group
                  id="radio-group-1"
                  v-model="formData.rating"
                  :options="[1, 2, 3, 4, 5]"
                  :state="formErrors.rating ? false : null"
                  name="radio-options"
                ></b-form-radio-group>
                <b-form-invalid-feedback v-if="formErrors.rating">
                  {{ formErrors.rating[0] }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="12" class="mb-1">
                <label class="d-block">Review</label>
                <b-form-textarea
                  v-model="formData.review"
                  placeholder="Masukkan review Anda"
                  :state="formErrors.review ? false : null"
                />
                <b-form-invalid-feedback v-if="formErrors.review">
                  {{ formErrors.review[0] }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <div class="float-right">
              <button class="btn btn-primary" @click="submit()">
                <i class="bi bi-check"></i> Simpan
              </button>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>


<script>
import axios from "@/libs/axios";
import {
  reqGetMyTrainingReview,
  reqUpdateMyTrainingReview,
} from "@/api/user/training";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardText,
  BAlert,
  BAvatar,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BFormTextarea,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BSpinner,
} from "bootstrap-vue";

export default {
  props: {
    active: {
      required: true,
    },
    training: {
      required: true,
    },
  },
  components: {
    BFormInvalidFeedback,
    BRow,
    BCol,
    BCard,
    BButton,
    BCardText,
    BAlert,
    BAvatar,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormTextarea,
    BFormRadioGroup,
    BSpinner,
  },
  data() {
    return {
      isLoading: false,
      formData: {
        review: "",
        rating: 5,
      },
      formErrors: [],
      isDone: 0,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.isLoading = true;
      reqGetMyTrainingReview(this.$route.params.trainingId)
        .then((response) => {
          this.formData.review = response.data.data.review;
          this.formData.rating = response.data.data.rating || 5;
          this.isDone = response.data.data.is_done;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Gagal",
            error.response.data.message || "Gagal mengambil review"
          );
        });
    },
    submit() {
      this.isLoading = true;
      this.formErrors = [];
      reqUpdateMyTrainingReview(this.$route.params.trainingId, this.formData)
        .then((response) => {
          this.isLoading = false;
          this.showToast(
            "success",
            "CheckIcon",
            "Berhasil",
            response.data.data.message || "Berhasil menyimpan review"
          );
          this.getData();
        })
        .catch((error) => {
          this.formErrors = error.response.data.errors;
          this.isLoading = false;
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Gagal",
            error.response.data.message || "Review Gagal Ditambahkan"
          );
        });
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.small-gap-right {
  margin-right: 8px;
}

.small-gap-bottom {
  margin-bottom: 8px;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.collapse-default {
  .card {
    // border-bottom: none !important;
  }
  .material-card {
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #efefef !important;
    border-radius: 0.428rem;

    .card-body {
      padding: 1.5rem;
    }
  }
}
</style>

