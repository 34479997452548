<template>
  <div>
    <div v-if="!isLoading && training.id">
      <classroom-header
        :header-data="headerData"
        :training="training"
        @reload="getTraining()"
      />
      <!-- Navigation Tab -->
      <div>
        <b-row class="mb-2">
          <b-col lg="12">
            <b-tabs pills class="profile-tabs mt-1 mt-md-0" nav-class="mb-0">
              <template #tabs-start>
                <b-nav-item
                  v-for="(navtab, index) in navTabs"
                  :key="index"
                  :active="currentTab === index"
                  class="font-weight-bold small-gap-right"
                  @click="currentTab = index"
                >
                  <i class="ti" :class="navtab.icon" />
                  <span class="d-none d-md-block">{{ navtab.title }}</span>
                </b-nav-item>
              </template>
            </b-tabs>
          </b-col>
        </b-row>
      </div>
      <div v-show="currentTab === 0" class="material-section mt-2">
        <classroom-content :training="training" />
      </div>
      <div
        v-if="currentTab === 1"
        class="attendance-section mt-2"
      >
        <classroom-attendance
          :chapters="chapters"
          :active="(currentTab === 1)" :training="training"
        />
      </div>
      <div
        v-if="currentTab === 2"
        class="attendance-section mt-2"
      >
        <classroom-review
          :active="(currentTab === 2)" :training="training"
        />
      </div>
    </div>

    <div v-if="!isLoading && !training.id">
      <b-row>
        <b-col class="d-flex justify-content-center mt-4">
          <div class="text-center pt-4">
            <b-img class="illustration-small" fluid :src="emptyStateImg" />
            <p class="mb-2">Pelatihan yang anda cari tidak ditemukan</p>
            <b-button
              variant="primary"
              class="mb-1 btn-sm-block"
              :to="{
                name: checkAuthorizeRole('trainings.index'),
              }"
              >Cari Pelatihan Lain</b-button
            >
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- Loading Detail Classroom-->
    <div v-if="isLoading" class="d-flex justify-content-center vertical-center">
      <div class="text-center mt-4">
        <b-spinner variant="primary" class="mr-1" />
        <p class="mt-1">Memuat Pelatihan</p>
      </div>
    </div>
  </div>
</template>

<script>
import { BRow, BTabs, BNavItem, BCol, BButton, BImg, BSpinner } from "bootstrap-vue";

import { reqGetTraining } from "@/api/user/training";
import { checkAuthorizeRole } from "@/auth/utils";
import ClassroomHeader from "./component/ClassroomHeader.vue";
import ClassroomContent from "./component/ClassroomContent.vue";
import ClassroomAttendance from './section/ClassroomAttendance.vue'
import ClassroomReview from './section/ClassroomReview.vue'

export default {
  components: {
    ClassroomHeader,
    ClassroomContent,
    ClassroomAttendance,
    ClassroomReview,
    BRow,
    BCol,
    BButton,
    BImg,
    BSpinner,
    BTabs,
    BNavItem
  },
  data() {
    return {
      isLoading: true,
      currentTab: 0,
      editMode: false,
      classroomId: this.$router.currentRoute.params.id,
      training: {},
      headerData: {
        classroomTitle: "class_title",
        classroomLogo: "/img/avatar-s-2.da5e73c7.jpg",
        teacherName: "teacher_name",
        classroomCovers: [],
        classroomDescription: "",
        classroomCoverImg: "https://picsum.photos/id/10/2500/1667",
      },
      emptyStateImg: require("@/assets/images/illustration/no-connection.svg"),
      chapters: [],
      navTabs: [
        { icon: "ti-book", title: "Pertemuan" },
        { icon: "ti-calendar", title: "Kehadiran" },
        { icon: "ti-star", title: "Review" },
      ],
    };
  },
  created() {
    this.getTraining();
  },
  methods: {
    checkAuthorizeRole,
    setChapters(chapters) {
      this.chapters = chapters;
    },
    async getTraining() {
      this.isLoading = true;
      await reqGetTraining(this.$route.params.trainingId)
        .then((response) => {
          this.training = response.data.data;
          const classroom = response.data.data;

          this.headerData.classroomTitle = classroom.title;
          this.headerData.classroomLogo = classroom.image;
          this.headerData.classroomCovers = classroom.training_banners;
          this.headerData.teacherName = classroom.trainer.name;
          this.headerData.classroomDescription = classroom.description;

          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    isEditModeOn(value) {
      this.editMode = value;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~@core/scss/vue/libs/vue-good-table.scss";

.card-img-overlay {
  padding: 0 !important;
}

.news-slide-content {
  border-radius: 0.428rem !important;

  img {
    height: 350px;
    border-radius: 0.428rem !important;
    object-fit: cover;
  }
}
</style>
