<template>
  <div>
    <b-row v-if="isLoading">
      <b-col class="d-flex justify-content-center mt-4">
        <div class="text-center mt-4">
          <b-spinner
            variant="primary"
            class="mr-1"
          />
          <p class="mt-1">
            Memuat Data Pertemuan
          </p>
        </div>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col md="12">
        <b-card>
          <!-- Header -->
          <div class="d-flex align-items-center line-height-1 pb-2">
            <i class="text-primary ti ti-list-details small-gap-right" />
            <h4 class="text-primary mb-0">
              Akses cepat daftar pertemuan
            </h4>
          </div>

          <!-- Quick Class Description -->
          <b-card
            bg-variant="transparent"
            class="border-light-secondary mb-1"
          >
            <b-card-text>
              {{ training.description }}
            </b-card-text>
          </b-card>

          <!-- TODO: integrating this announcement if api already exist -->
          <!-- Announcement -->
          <h5
            v-if="training.announcement"
            class="font-weight-bolder py-1"
          >
            Pengumuman
          </h5>
          <b-alert
            v-if="training.announcement"
            show
            variant="warning"
          >
            <div class="alert-body p-2">
              <div class="d-flex">
                <div class="mr-1">
                  <b-avatar
                    rounded="lg"
                    class="bg-white"
                  >
                    <i class="card-icon text-warning ti ti-bell" />
                  </b-avatar>
                </div>
                <div class="d-flex flex-column">
                  <span
                    class="font-weight-normal"
                  >{{ training.announcement }}
                  </span>
                </div>
              </div>
            </div>
          </b-alert>

          <!-- Material List -->
          <h5 class="font-weight-bolder mt-1 small-gap-bottom">
            Daftar Pertemuan
          </h5>
          <app-collapse type="default">
            <app-collapse-item
              v-for="(content, index) in chapters"
              :key="index"
              :title="`Pertemuan Ke-${index + 1} : ${content.title}`"
              :badge-text="content.is_finish_read && content.is_finish_task ? 'Selesai' : ''"
              :badge-variant="content.is_finish_read && content.is_finish_task ? 'light-success' : ''"
              :is-visible="isChapterVisible(content)"
            >
              <!-- Date -->
              <span class="text-muted"> {{ content.start_at | moment("dddd, Do MMM YYYY HH:mm") }} - {{ content.end_at | moment("dddd, Do MMM YYYY HH:mm") }} </span>
              <b-row class="mt-1">
                <b-col
                  lg="12"
                  class="small-gap-bottom"
                >
                  <!-- Materi Card -->
                  <b-card
                    v-show="content.id"
                    bg-variant="transparent"
                    class="material-card border-light-secondary"
                  >
                    <div class="d-flex">
                      <div class="mr-1">
                        <b-avatar
                          rounded="lg"
                          variant="light-success"
                        >
                          <i class="card-icon ti ti-book-2" />
                        </b-avatar>
                      </div>
                      <div class="d-flex flex-column w-100">
                        <div class="d-flex justify-content-between">
                          <div class="d-flex flex-column">
                            <!-- Dynamic Material Title -->
                            <span class="text-muted">Materi</span>
                            <span class="text-muted">Durasi: {{ content.training_duration }} {{ content.duration_type }}</span>
                            <span
                              v-if="content.is_finish_read"
                              class="text-success"
                            ><i class="bi bi-check" /> Sudah Diikuti</span>
                            <span
                              v-else-if="!content.is_finish_read && content.my_view"
                              class="text-warning"
                            ><i class="bi bi-clock" /> Durasi pelatihan belum tercukupi</span>
                            <span
                              v-else
                              class="text-danger"
                            ><i class="bi bi-x" /> Belum Diikuti</span>
                          </div>
                          <div>
                            <b-button
                              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                              variant="flat-primary"
                              @click="triggerDetailModal(content.id)"
                            >
                              <span
                                class="align-middle mr-1"
                              >Buka</span>
                              <i class="align-middle ti ti-arrow-right" />
                            </b-button>
                          </div>
                        </div>

                        <b-card-text>
                          {{ content.description }}
                        </b-card-text>
                      </div>
                    </div>
                  </b-card>
                </b-col>
                <b-col
                  lg="12"
                  class="small-gap-bottom"
                >
                  <!-- Task Card -->
                  <b-card
                    v-if="content.has_task && content.has_task != 0"
                    bg-variant="transparent"
                    class="material-card border-light-secondary"
                  >
                    <div class="d-flex">
                      <div class="mr-1">
                        <b-avatar
                          rounded="lg"
                          variant="light-info"
                        >
                          <i class="card-icon ti ti-clipboard-list" />
                        </b-avatar>
                      </div>
                      <div class="d-flex flex-column w-100">
                        <div class="d-flex justify-content-between">
                          <div class="d-flex flex-column">
                            <span class="text-muted">Tugas</span>
                            <span
                              v-if="content.task.my_result && content.task.my_result.status == 'Selesai'"
                              class="text-success"
                            ><i class="bi bi-check" /> Selesai</span>
                            <span
                              v-else
                              class="text-warning"
                            ><i class="bi bi-x" />{{ content.task.my_result ? content.task.my_result.status : 'Belum Dikerjakan' }}</span>
                            <span
                              v-if="!content.task.my_result || (content.task.my_result && content.task.my_result.status != 'Selesai')"
                              class="text-danger"
                            > Kerjakan Sebelum {{ content.task.end_at | moment("dddd, Do MMM YYYY HH:mm") }} </span>
                          </div>
                          <div>
                            <b-button
                              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                              variant="flat-primary"
                              :to="{
                                name: checkAuthorizeRole(
                                  'chapter-tasks.show'
                                ),
                                params: {
                                  trainingId: $route.params.trainingId,
                                  chapterId: content.id,
                                },
                              }"
                            >
                              <span
                                class="align-middle mr-1"
                              >Buka</span>
                              <i class="align-middle ti ti-arrow-right" />
                            </b-button>
                          </div>
                        </div>

                        <b-card-text>
                          {{ content.task.description }}
                        </b-card-text>
                      </div>
                    </div>
                  </b-card>
                </b-col>
              </b-row>

              <!-- Adding Material, Task, and Exam -->
            </app-collapse-item>
          </app-collapse>
        </b-card>
      </b-col>
    </b-row>

    <!-- Add Content Modal -->
    <classroom-content-add
      @reload="loadChapters"
      @pushNewData="pushNewData"
    />
    <classroom-content-detail
      :chapter-id="contentId"
      @reload="loadChapters"
      @pushNewData="pushNewData"
    />
  </div>
</template>

<script>
import { checkAuthorizeRole } from '@/auth/utils'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardText,
  BAlert,
  BAvatar,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { reqGetTrainingChapters } from '@/api/user/trainingChapter'
import ClassroomContentAdd from './ClassroomContentAddModal.vue'
import ClassroomContentDetail from './ClassroomContentDetailModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardText,
    BAlert,
    BAvatar,
    BSpinner,
    AppCollapse,
    AppCollapseItem,
    ClassroomContentAdd,
    ClassroomContentDetail,
  },
  directives: {
    Ripple,
  },
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
    training: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      classroomId: this.$router.currentRoute.params.id,
      chapters: [],
      contentId: '',
      chapterVisibleCount: 0,
    }
  },
  mounted() {
    this.loadChapters()
  },
  methods: {
    chaperStatus(content) {
      if (content.is_finish_read && content.is_finish_task) { return "<span class='text-success'><i class='bi bi-check'></i>selesai</span>" }
    },
    isChapterVisible(content) {
      if ((!content.is_finish_read || !content.is_finish_task) && this.chapterVisibleCount == 0) {
        this.chapterVisibleCount += 1
        return true
      }
      return false
    },
    pushNewData(data) {
      this.chapters.push(data)
    },
    loadChapters() {
      this.isLoading = true
      reqGetTrainingChapters(this.$route.params.trainingId).then(response => {
        this.chapters = response.data.data
        this.isLoading = false
        this.$emit('setChapters', response.data.data)
      }).catch(() => {
        this.isLoading = false
      })
    },
    triggerModal() {
      this.$bvModal.show('content-add-modal')
    },
    triggerDetailModal(contentId) {
      this.contentId = contentId
      this.$bvModal.show('content-detail-modal')
    },
    checkAuthorizeRole,
  },
}
</script>

<style lang="scss" scoped>
.small-gap-right {
  margin-right: 8px;
}

.small-gap-bottom {
  margin-bottom: 8px;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.collapse-default {
  .card {
    // border-bottom: none !important;
  }
  .material-card {
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #efefef !important;
    border-radius: 0.428rem;

    .card-body {
      padding: 1.5rem;
    }
  }
}
</style>
