var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"classroom-header mb-1"},[_c('b-carousel',{staticStyle:{"text-shadow":"1px 1px 2px #333"},attrs:{"id":"carousel-1","interval":40000,"controls":"","indicators":"","background":"#ababab"}},_vm._l((_vm.headerData.classroomCovers),function(item,index){return _c('b-carousel-slide',{key:index,scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('img',{staticClass:"d-block w-100 training-banner",attrs:{"src":item.image,"alt":"image slot"}})]},proxy:true}],null,true)})}),1),_c('div',{staticClass:"classroom-header-nav"},[_c('div',{staticClass:"p-1 p-sm-2 d-flex align-items-center"},[_c('b-img',{staticClass:"classroom-img",attrs:{"src":_vm.headerData.classroomLogo,"rounded":"","fluid":"","alt":"classroom photo"}}),_c('div',{staticClass:"w-100 ml-2 d-flex justify-content-between"},[_c('div',[(
                _vm.training.join_status &&
                  _vm.training.join_status.status != 'Ditolak'
              )?[(_vm.training.join_status.status == 'Diterima')?_c('b-badge',{staticClass:"enrolled-badge",attrs:{"variant":"light-success"}},[_c('i',{staticClass:"ti ti-check"}),_vm._v(" Terdaftar ")]):(
                  _vm.training.join_status.status == 'Menunggu Persetujuan'
                )?_c('b-badge',{staticClass:"enrolled-badge",attrs:{"variant":"light-warning"}},[_c('i',{staticClass:"ti ti-clock"}),_vm._v(" Menunggu Persetujuan ")]):(
                  _vm.training.join_status.status == 'Menunggu Pembayaran'
                )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"button d-flex align-items-center",attrs:{"variant":"outline-danger","size":"sm","to":{
                  name: _vm.checkAuthorizeRole('transactions.show'),
                  params: {
                    id: _vm.training.join_status.payment.id
                  }
                }}},[_c('i',{staticClass:"ti ti-shopping-cart"}),_c('span',{staticClass:"align-middle"},[_vm._v("Bayar Sekarang")])]):_vm._e()]:_c('b-badge',{staticClass:"enrolled-badge",attrs:{"variant":"light-primary"},on:{"click":function($event){return _vm.reqJoin()}}},[_c('i',{staticClass:"ti ti-plus"}),_vm._v(" Daftar ")]),_c('h3',[_vm._v(" "+_vm._s(_vm.headerData.classroomTitle)+" ")]),_c('div',{staticClass:"text-danger"},[_c('span',{staticClass:"badge",class:[
                  _vm.training.status == 'Belum Mulai'
                    ? 'badge-secondary'
                    : _vm.training.status == 'Pendaftaran'
                      ? 'badge-warning'
                      : _vm.training.status == 'Sedang Berlangsung'
                        ? 'badge-danger'
                        : 'badge-success' ]},[_vm._v(_vm._s(_vm.training.status))]),_c('div',{staticClass:"d-block d-sm-inline"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.training.start_at,"Do MMM YYYY"))+" - "+_vm._s(_vm._f("moment")(_vm.training.end_at,"Do MMM YYYY"))+" ")])]),_c('div',{staticClass:"d-flex mt-1"},[_c('b-badge',{staticClass:"mr-1",attrs:{"variant":"light-info"}},[_c('i',{staticClass:"ti ti-user"}),_vm._v(" "+_vm._s(_vm.headerData.teacherName)+" ")])],1)],2),_c('div',{staticClass:"enrolled-button"},[(
                _vm.training.join_status &&
                  _vm.training.join_status.status != 'Ditolak'
              )?[(_vm.training.join_status.status == 'Diterima')?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"button d-flex align-items-center",attrs:{"variant":"outline-success","disabled":""}},[_c('i',{staticClass:"ti ti-check"}),_c('span',{staticClass:"align-middle"},[_vm._v("Terdaftar")])]):(
                  _vm.training.join_status.status == 'Menunggu Pembayaran'
                )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"button d-flex align-items-center",attrs:{"variant":"outline-danger","to":{
                  name: _vm.checkAuthorizeRole('transactions.show'),
                  params: {
                    id: _vm.training.join_status.payment.id
                  }
                }}},[_c('i',{staticClass:"ti ti-shopping-cart"}),_c('span',{staticClass:"align-middle"},[_vm._v("Bayar Sekarang")])]):(
                  _vm.training.join_status.status == 'Menunggu Persetujuan'
                )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"button d-flex align-items-center",attrs:{"variant":"outline-warning","disabled":""}},[_c('i',{staticClass:"ti ti-clock"}),_c('span',{staticClass:"align-middle"},[_vm._v("Menunggu Persetujuan")])]):_vm._e()]:_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"button d-flex align-items-center",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.reqJoin()}}},[_c('i',{staticClass:"ti ti-plus"}),_c('span',{staticClass:"align-middle"},[_vm._v("Daftar")])]),_c('div',{staticClass:"price-section"},[_c('div',{staticClass:"base-price"},[_vm._v(" "+_vm._s(_vm.formatRupiah(_vm.basePrice))+" ")]),(_vm.discount)?_c('div',{staticClass:"final-price"},[_vm._v(" "+_vm._s(_vm.formatRupiah(_vm.finalPrice))+" ")]):_vm._e()])],2)])],1)])],1),_c('enroll-card',{attrs:{"classroom-data":_vm.training},on:{"reload":function($event){return _vm.$emit('reload')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }