<template>
  <!-- Content Add Modal -->
  <b-modal
    id="content-detail-modal"
    :hide-footer="false"
    centered
    :title="data.title"
    size="lg"
    :modal-class="isFullscreen ? 'fullscreen-modal' : ''"
    :hide-header-close="isFullscreen"
  >
    <b-row v-if="isLoading">
      <b-col class="d-flex justify-content-center mt-4">
        <div class="text-center my-auto">
          <b-spinner
            variant="primary"
            class="mr-1"
          />
          <p class="mt-1">
            Memuat Materi
          </p>
        </div>
      </b-col>
    </b-row>
    <div v-else>
      <div
        v-if="data.content_type == 'E-Book'"
        class="pdf-viewer"
      >
        <iframe
          :src="data.content"
          frameborder="0"
          style="overflow:hidden;height:100%;width:100%"
          height="100%"
          width="100%"
        />
      </div>
      <video
        v-if="data.content_type == 'Video'"
        :src="data.content"
        class="w-100"
        controls
      />
    </div>
    <template #modal-footer>
      <div
        v-if="!isLoading"
        class="w-100 text-center"
      >
        <b-button
          v-if="!isFullscreen"
          variant="primary"
          @click="isFullscreen = true"
        ><i class="ti ti-zoom-in small-gap-right" /> Perbesar</b-button>
        <b-button
          v-else
          variant="warning"
          @click="isFullscreen = false"
        ><i class="ti ti-zoom-out small-gap-right" /> Perkecil</b-button>
        <b-button
          v-if="!isFullscreen"
          variant="primary"
          class="ml-1"
          @click="downloadContent()"
        ><i class="ti ti-download small-gap-right" /> Download</b-button>
        <b-button
          v-if="!isFullscreen && data.online_meet_url"
          variant="primary"
          class="ml-1 mt-1 mt-sm-0"
          target="blank"
          :href="data.online_meet_url"
        ><i class="ti ti-link small-gap-right" /> Buka Link</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
  BSpinner,
  BCol,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { reqGetTrainingChapter } from '@/api/user/trainingChapter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    BButton,
    BSpinner,
    BCol,
    BRow,
  },
  directives: {
    Ripple,
  },
  props: {
    chapterId: '',
  },
  data() {
    return {
      isLoading: false,
      classroomId: this.$router.currentRoute.params.id,
      data: {},
      isFullscreen: false,
      loadChapter: false,
    }
  },
  watch: {
    loadChapter(value) {
      if (value) {
        this.getChapter()
      }
    },
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      this.loadChapter = true
    })
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.loadChapter = false
    })
  },
  methods: {
    async getChapter() {
      if (this.chapterId) {
        this.isLoading = true
        reqGetTrainingChapter(this.chapterId)
          .then(response => {
            this.data = response.data.data
            this.isLoading = false
          })
          .catch(error => {
            this.data = {}
            this.isLoading = false
            this.$bvModal.hide('content-detail-modal')
            this.$swal({
              title: 'Oops',
              text: error.response.data.message,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Oke',
              cancelButtonText: 'Batal',
            }).then(() => {
            })
          })
      }
    },
    downloadContent() {
      window.location.href = this.data.download_url
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Berhasil',
          icon: 'CheckIcon',
          text: 'Konten sedang didownload',
          variant: 'success',
        },
      })
    },
  },
}
</script>

<style lang="scss">
#content-detail-modal {
  .modal-body {
    padding: 1.5rem 2rem;
    overflow: scroll;
  }

  .pdf-viewer {
    height: 500px;
    margin:0px;
    padding:0px;
    overflow:hidden;
  }
}
.fullscreen-modal .modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh !important;
  display: flex;
  position: fixed;
  z-index: 100000;

  .modal-content {
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh !important;

  .pdf-viewer {
    height: 80vh !important;
    margin:0px;
    padding:0px;
    overflow:hidden;
  }
  }
}
</style>
