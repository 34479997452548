<template>
  <div>
    <b-card class="classroom-header mb-1">
      <b-carousel
        id="carousel-1"
        :interval="40000"
        controls
        indicators
        background="#ababab"
        style="text-shadow: 1px 1px 2px #333"
      >
        <!-- Text slides with image -->
        <b-carousel-slide
          v-for="(item, index) in headerData.classroomCovers"
          :key="index"
        >
          <template #img>
            <img
              class="d-block w-100 training-banner"
              :src="item.image"
              alt="image slot"
            >
          </template>
        </b-carousel-slide>
      </b-carousel>
      <!-- profile navbar -->
      <div class="classroom-header-nav">
        <div class="p-1 p-sm-2 d-flex align-items-center">
          <b-img
            class="classroom-img"
            :src="headerData.classroomLogo"
            rounded
            fluid
            alt="classroom photo"
          />
          <div class="w-100 ml-2 d-flex justify-content-between">
            <div>
              <template
                v-if="
                  training.join_status &&
                    training.join_status.status != 'Ditolak'
                "
              >
                <b-badge
                  v-if="training.join_status.status == 'Diterima'"
                  class="enrolled-badge"
                  variant="light-success"
                >
                  <i class="ti ti-check" />
                  Terdaftar
                </b-badge>
                <b-badge
                  v-else-if="
                    training.join_status.status == 'Menunggu Persetujuan'
                  "
                  class="enrolled-badge"
                  variant="light-warning"
                >
                  <i class="ti ti-clock" />
                  Menunggu Persetujuan
                </b-badge>
                <b-button
                  v-else-if="
                    training.join_status.status == 'Menunggu Pembayaran'
                  "
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="button d-flex align-items-center"
                  variant="outline-danger"
                  size="sm"
                  :to="{
                    name: checkAuthorizeRole('transactions.show'),
                    params: {
                      id: training.join_status.payment.id
                    }
                  }"
                >
                  <i class="ti ti-shopping-cart" />
                  <span class="align-middle">Bayar Sekarang</span>
                </b-button>
              </template>
              <b-badge
                v-else
                class="enrolled-badge"
                variant="light-primary"
                @click="reqJoin()"
              >
                <i class="ti ti-plus" />
                Daftar
              </b-badge>

              <h3>
                {{ headerData.classroomTitle }}
              </h3>

              <div class="text-danger">
                <span
                  :class="[
                    training.status == 'Belum Mulai'
                      ? 'badge-secondary'
                      : training.status == 'Pendaftaran'
                        ? 'badge-warning'
                        : training.status == 'Sedang Berlangsung'
                          ? 'badge-danger'
                          : 'badge-success',
                  ]"
                  class="badge"
                >{{ training.status }}</span>
                <div class="d-block d-sm-inline">
                  {{ training.start_at | moment("Do MMM YYYY") }} -
                  {{ training.end_at | moment("Do MMM YYYY") }}
                </div>
              </div>
              <div class="d-flex mt-1">
                <b-badge
                  class="mr-1"
                  variant="light-info"
                >
                  <i class="ti ti-user" />
                  {{ headerData.teacherName }}
                </b-badge>
              </div>
            </div>
            <div class="enrolled-button">
              <template
                v-if="
                  training.join_status &&
                    training.join_status.status != 'Ditolak'
                "
              >
                <b-button
                  v-if="training.join_status.status == 'Diterima'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="button d-flex align-items-center"
                  variant="outline-success"
                  disabled
                >
                  <i class="ti ti-check" />
                  <span class="align-middle">Terdaftar</span>
                </b-button>
                <b-button
                  v-else-if="
                    training.join_status.status == 'Menunggu Pembayaran'
                  "
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="button d-flex align-items-center"
                  variant="outline-danger"
                  :to="{
                    name: checkAuthorizeRole('transactions.show'),
                    params: {
                      id: training.join_status.payment.id
                    }
                  }"
                >
                  <i class="ti ti-shopping-cart" />
                  <span class="align-middle">Bayar Sekarang</span>
                </b-button>
                <b-button
                  v-else-if="
                    training.join_status.status == 'Menunggu Persetujuan'
                  "
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="button d-flex align-items-center"
                  variant="outline-warning"
                  disabled
                >
                  <i class="ti ti-clock" />
                  <span class="align-middle">Menunggu Persetujuan</span>
                </b-button>
              </template>
              <b-button
                v-else
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="button d-flex align-items-center"
                variant="outline-primary"
                @click="reqJoin()"
              >
                <i class="ti ti-plus" />
                <span class="align-middle">Daftar</span>
              </b-button>
              <div class="price-section">
                <div class="base-price">
                  {{ formatRupiah(basePrice) }}
                </div>
                <div
                  v-if="discount"
                  class="final-price"
                >
                  {{ formatRupiah(finalPrice) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>
    <!-- Enroll Modal -->
    <enroll-card
      :classroom-data="training"
      @reload="$emit('reload')"
    />
  </div>
</template>

<script>
import {
  BBadge,
  BCard,
  BImg,
  BFormCheckbox,
  BCarousel,
  BCarouselSlide,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import EnrollCard from '@core/layouts/components/card/enroll-card/EnrollCard.vue'
import { reqJoinTraining } from '@/api/user/training'
import { checkAuthorizeRole } from '@/auth/utils'

export default {
  components: {
    BCard,
    BBadge,
    BImg,
    BFormCheckbox,
    BCarousel,
    BCarouselSlide,
    BButton,
    EnrollCard,
  },
  directives: {
    Ripple,
  },
  props: {
    headerData: {
      type: Object,
      default: () => { },
    },
    training: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      isEditClassroomMode: false,
    }
  },
  computed: {
    basePrice() {
      return this.training.price
    },
    finalPrice() {
      return this.basePrice - this.discountPrice
    },
    discountPrice() {
      return this.discount * this.basePrice
    },
    discount() {
      return this.training.discount / 100
    },
  },
  methods: {
    reqJoin() {
      if (this.training.need_requirement || this.training.code) {
        this.$bvModal.show('enroll-modal')
      } else {
        this.joinTraining(this.training.id)
      }
    },
    joinTraining(id) {
      reqJoinTraining(id)
        .then(response => {
          this.$swal({
            title: 'Berhasil',
            text: response.data.message,
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Oke',
          })
          this.$emit('reload')
        })
        .catch(error => {
          this.$swal({
            title: 'Gagal',
            text: error.response.data.message || 'Gagal mendaftar pelatihan',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Oke',
          })
        })
    },
    toggleEditClassroomMode(event) {
      this.isEditClassroomMode = event
      this.$emit('isEditModeOn', this.isEditClassroomMode)
    },
    toggleNavtab(tab) {
      this.activeNavtab = tab
      this.$emit('activeNavtab', this.activeNavtab)
    },
    avatarText,
    checkAuthorizeRole,
    formatRupiah(value) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0,
      }).format(value)
    },
  },
}
</script>
<style lang="scss" scoped>
@media (max-width: 576px) {
  .classroom-header {
    .enrolled-badge {
      display: block;
    }

    .card-img-top {
      max-height: 100px;
    }

    .classroom-header-nav {
      .classroom-img {
        // display: none;
        z-index: 1;
        border: 0.157rem solid #fff;
        width: calc(20vw);
        height: calc(20vw);
        margin-top: 0;
      }
    }
  }

  .enrolled-button {
    display: none;
  }
}

.enrolled-badge {
  display: none;
}

.card-img-top {
  max-height: 200px;
  object-fit: cover;
  // object-position: top;
}

.classroom-header {
  .classroom-img {
    z-index: 1;
    border: 0.357rem solid #fff;
    background-color: #fff;
    border-radius: 0.428rem;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin-top: -65px;
  }
}

.button {
  i {
    margin-right: 0.5rem;
  }
}

.carousel {
  aspect-ratio: auto 10 / 1;
}

.carousel .carousel-item .img-fluid {
  max-height: 100% !important;
  max-width: 100% !important;
}

.training-banner {
  width: 100%;
  height: calc(100vw / 5);
}

.price-section {
  margin-top: 10px;
}

.price-section .base-price {
  font-size: medium;
  text-decoration: line-through;
}

.price-section .final-price {
  color: red;
  font-size: large;
  font-weight: 500;
}
</style>
